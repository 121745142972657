import React from 'react'
import { EllicotLayout } from 'components/EllicotLayout'
import { DiscoverCommunitiesPage } from 'components/DiscoverCommunitiesPage'
import { FeaturedCommunity } from 'components/FeaturedCommunity'

const CommunitiesPage: React.FunctionComponent = () => {
  return (
    <EllicotLayout>
      <DiscoverCommunitiesPage />
    </EllicotLayout>
  )
}

export default CommunitiesPage

import React from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'
import { Link } from 'gatsby'
import img from '../images/richmondHillHero.jpg'
import img2 from '../images/vaughanCenter2.jpg'
import img3 from '../images/markham2.jpg'
import img4 from '../images/mimicoHero.jpg'
import img5 from '../images/northYorkHero.jpg'
import img6 from '../images/aurora2.jpg'
import img7 from '../images/newmarket.jpg'
import img8 from '../images/stouffville.jpg'
import img9 from '../images/king-city.jpg'
import img10 from '../images/stCaths.jpg'

const CardTitle = styled.p`
  font-family: CentraNo2-Bold;
  color: #fff;
  margin-bottom: 0.25em;
  -webkit-font-smoothing: antialiased;
  font-size: 1em !important;
  text-align: center;
`

const TitleH1 = styled.h1`
  font-family: CentraNo2-Bold;
  font-size: 3em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #1c1c1c;
  margin: 2em 0 0.25em;
`

const SubTitle = styled.p`
  font-family: CentraNo2-Light;
  font-weight: 500;
  font-size: 1.1em;
  margin: 0 3em 3em;
`

const DivWrapper = styled.div`
  max-width: 90%;
  margin: auto;
  padding: 2em 0 5em;
`

const FeaturedTitle = styled.h3`
  font-family: CentraNo2-Medium;
  font-size: 2em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  margin: 0;
`

const FeaturedTitle2 = styled.h3`
  font-family: CentraNo2-Medium;
  font-size: 2em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  margin: 0;
`

const FeaturedSubtitle = styled.p`
  font-family: CentraNo2-Light;
  font-weight: 100;
  font-size: 0.8em;
`

const FeaturedSubtitle2 = styled.p`
  font-family: CentraNo2-Light;
  font-weight: 100;
  font-size: 0.8em;
  max-width: 70%;
`

const ButtonSubscribe = styled.button`
  background: #000;
  color: #fff;
  padding: 15px;
  border: none;
  width: 25%;
  font-family: CentraNo2-Book;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  font-size: 0.8em;
`

export const DiscoverCommunitiesPage: React.FunctionComponent = () => {
  return (
    <DivWrapper style={{ marginTop: '3em' }} className="discoverCommPage">
      <FlexDiv
        style={{ display: 'flex', alignItems: 'center' }}
        className="discoverCommPageWrapper"
      >
        <FlexDiv>
          <FlexDiv
            style={{
              flex: 19.25,
              background: '#1c1c1c',
              height: 200,
              color: 'white',
              marginLeft: '0.5em',
              marginRight: '0.5em',
              placeSelf: 'center',
            }}
            className="discoverCommBlackBlock"
          >
            <div
              style={{
                alignContent: 'center',
                padding: '2em',
                placeSelf: 'center',
              }}
            >
              <h1 style={{ margin: '0 0 0.5em' }}>Discover Communities</h1>
              <p
                style={{
                  margin: 0,
                  fontSize: '0.7em',
                  fontFamily: 'CentraNo2-Book',
                }}
              >
                {' '}
                Your next homes location can have a large impact on your
                lifestyle. Researching your next neighbourhood very important.
                Ellicott Realty has proudly selling property all over the GTA,
                including internationally. These are just a few of the area’s
                that we have been selling in. Let’s discover where we can go
                next.
              </p>
            </div>
          </FlexDiv>

          <FlexDiv
            style={{ flex: 10, background: '', color: 'white' }}
            className="desktopOnly"
          >
            <FlexDiv
              size={1}
              justify="center"
              align="center"
              style={{
                margin: 10,
                height: 200,
                width: 300,
                color: 'white',
                backgroundSize: 'cover',
                backgroundImage: 'url(' + img + ')',
                backgroundBlendMode: 'multiply',
                backgroundColor: '#666666bd',
              }}
              className="CommImgWrapper"
            >
              <CardTitle>
                <Link
                  to="/richmondhill"
                  style={{
                    textDecoration: 'none',
                    marginRight: '25px',
                    color: '#fff',
                  }}
                >
                  Richmond Hill
                </Link>
              </CardTitle>
            </FlexDiv>
          </FlexDiv>
          <FlexDiv style={{ flex: 10, background: '', color: 'white' }}>
            <FlexDiv
              size={1}
              justify="center"
              align="center"
              style={{
                margin: 10,
                height: 200,
                width: 300,
                color: 'white',
                backgroundSize: 'cover',
                backgroundImage: 'url(' + img2 + ')',
                backgroundBlendMode: 'multiply',
                backgroundColor: '#666666bd',
              }}
              className="CommImgWrapper"
            >
              <CardTitle>
                <Link
                  to="/vaughan"
                  style={{
                    textDecoration: 'none',
                    marginRight: '25px',
                    color: '#fff',
                  }}
                >
                  Vaughan
                </Link>
              </CardTitle>
            </FlexDiv>
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>
      <FlexDiv style={{ display: 'flex' }} className="mobileColumn">
        <FlexDiv
          style={{ flex: 10, background: '', color: 'white' }}
          className="mobileOnlyOD"
        >
          <FlexDiv
            size={1}
            justify="center"
            align="center"
            style={{
              margin: 10,
              height: 200,
              width: 200,
              color: 'white',
              backgroundSize: 'cover',
              backgroundImage: 'url(' + img + ')',
              backgroundBlendMode: 'multiply',
              backgroundColor: '#666666bd',
            }}
            className="CommImgWrapper"
          >
            <CardTitle>
              <Link
                to="/richmondhill"
                style={{
                  textDecoration: 'none',
                  marginRight: '25px',
                  color: '#fff',
                }}
              >
                Richmond Hill
              </Link>
            </CardTitle>
          </FlexDiv>
        </FlexDiv>
        <FlexDiv style={{ flex: 10, background: '', color: 'white' }}>
          <FlexDiv
            size={1}
            justify="center"
            align="center"
            style={{
              margin: 10,
              height: 200,
              width: 200,
              color: 'white',
              backgroundSize: 'cover',
              backgroundImage: 'url(' + img3 + ')',
              backgroundBlendMode: 'multiply',
              backgroundColor: '#666666bd',
            }}
            className="CommImgWrapper"
          >
            <CardTitle>
              <Link
                to="/markham"
                style={{
                  textDecoration: 'none',
                  marginRight: '25px',
                  color: '#fff',
                }}
              >
                Markham
              </Link>
            </CardTitle>
          </FlexDiv>
        </FlexDiv>
        <FlexDiv style={{ flex: 10, color: 'white' }}>
          <FlexDiv
            size={1}
            justify="center"
            align="center"
            style={{
              margin: 10,
              height: 200,
              width: 250,
              color: 'white',
              backgroundSize: 'cover',
              backgroundImage: 'url(' + img4 + ')',
              backgroundBlendMode: 'multiply',
              backgroundColor: '#666666bd',
            }}
            className="CommImgWrapper"
          >
            <CardTitle>
              <Link
                to="/etobicoke"
                style={{
                  textDecoration: 'none',
                  marginRight: '25px',
                  color: '#fff',
                }}
              >
                Etobicoke
              </Link>
            </CardTitle>
          </FlexDiv>
        </FlexDiv>
        <FlexDiv style={{ flex: 10, color: 'white' }}>
          <FlexDiv
            size={1}
            justify="center"
            align="center"
            style={{
              margin: 10,
              height: 200,
              width: 250,
              color: 'white',
              backgroundSize: 'cover',
              backgroundImage: 'url(' + img5 + ')',
              backgroundBlendMode: 'multiply',
              backgroundColor: '#666666bd',
            }}
            className="CommImgWrapper"
          >
            <CardTitle>
              <Link
                to="/northyork"
                style={{
                  textDecoration: 'none',
                  marginRight: '25px',
                  color: '#fff',
                }}
              >
                North York
              </Link>
            </CardTitle>
          </FlexDiv>
        </FlexDiv>
        <FlexDiv style={{ flex: 10, color: 'white' }}>
          <FlexDiv
            size={1}
            justify="center"
            align="center"
            style={{
              margin: 10,
              height: 200,
              width: 250,
              color: 'white',
              backgroundSize: 'cover',
              backgroundImage: 'url(' + img6 + ')',
              backgroundBlendMode: 'multiply',
              backgroundColor: '#666666bd',
            }}
            className="CommImgWrapper"
          >
            <CardTitle>
              <Link
                to="/aurora"
                style={{
                  textDecoration: 'none',
                  marginRight: '25px',
                  color: '#fff',
                }}
              >
                Aurora
              </Link>
            </CardTitle>
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>

      <FlexDiv style={{ display: 'flex' }} className="fullWidthFlex">
        <FlexDiv style={{ flex: 10, background: '', color: 'white' }}>
          <FlexDiv
            size={1}
            justify="center"
            align="center"
            style={{
              margin: 10,
              height: 200,
              width: 200,
              color: 'white',
              backgroundSize: 'cover',
              backgroundImage: 'url(' + img7 + ')',
              backgroundBlendMode: 'multiply',
              backgroundColor: '#666666bd',
            }}
            className="discoverCommWidthWrap"
          >
            <CardTitle>
              <Link
                to="/newmarket"
                style={{
                  textDecoration: 'none',
                  marginRight: '25px',
                  color: '#fff',
                }}
              >
                Newmarket
              </Link>
            </CardTitle>
          </FlexDiv>
        </FlexDiv>
        <FlexDiv style={{ flex: 10, color: 'white' }}>
          <FlexDiv
            size={1}
            justify="center"
            align="center"
            style={{
              margin: 10,
              height: 200,
              width: 250,
              color: 'white',
              backgroundSize: 'cover',
              backgroundImage: 'url(' + img8 + ')',
              backgroundBlendMode: 'multiply',
              backgroundColor: '#666666bd',
            }}
            className="discoverCommWidthWrap"
          >
            <CardTitle>
              <Link
                to="/stouffville"
                style={{
                  textDecoration: 'none',
                  marginRight: '25px',
                  color: '#fff',
                }}
              >
                Stouffville
              </Link>
            </CardTitle>
          </FlexDiv>
        </FlexDiv>
        <FlexDiv style={{ flex: 10, color: 'white' }}>
          <FlexDiv
            size={1}
            justify="center"
            align="center"
            style={{
              margin: 10,
              height: 200,
              width: 250,
              color: 'white',
              backgroundSize: 'cover',
              backgroundImage: 'url(' + img9 + ')',
              backgroundBlendMode: 'multiply',
              backgroundColor: '#666666bd',
            }}
            className="discoverCommWidthWrap"
          >
            <CardTitle>
              <Link
                to="/kingcity"
                style={{
                  textDecoration: 'none',
                  marginRight: '25px',
                  color: '#fff',
                }}
              >
                King City
              </Link>
            </CardTitle>
          </FlexDiv>
        </FlexDiv>
        <FlexDiv style={{ flex: 10, color: 'white' }}>
          <FlexDiv
            size={1}
            justify="center"
            align="center"
            style={{
              margin: 10,
              height: 200,
              width: 250,
              color: 'white',
              backgroundSize: 'cover',
              backgroundBlendMode: 'multiply',
              backgroundColor: 'rgb(28, 28, 28)',
            }}
            className="discoverCommWidthWrap"
          >
            <CardTitle>
              <Link
                to="/contact"
                style={{
                  textDecoration: 'none',
                  marginRight: '25px',
                  color: '#fff',
                  textTransform: 'uppercase',
                }}
              >
                Get In Touch
              </Link>
            </CardTitle>
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>

      <FlexDiv
        container
        style={{
          margin: '10px',
        }}
        className="mobileColumn commuitiesFeaturedWrapper"
      >
        <FlexDiv
          size={1}
          justify="center"
          align="center"
          style={{
            color: 'white',
            background: '#1C1C1C',
            paddingRight: '0.8em',
          }}
        >
          <FlexDiv
            container
            style={{
              flexWrap: 'wrap',
              padding: '0 0 0 2.5em',
            }}
            className="featuredCommpageWrapper"
          >
            <CardTitle className="featuredCommTitle">
              Featured Community
            </CardTitle>
          </FlexDiv>
        </FlexDiv>
        <FlexDiv
          size={2}
          justify="center"
          align="center"
          style={{
            padding: 50,
            color: 'white',
            backgroundSize: 'cover',
            backgroundImage: 'url(' + img10 + ')',
            backgroundBlendMode: 'multiply',
            backgroundColor: '#666666bd',
            backgroundPosition: 'right',
            flex: '1.5',
          }}
          className="stCathWrapper"
        >
          <FlexDiv
            container
            style={{
              flexWrap: 'wrap',
              padding: '0 0 0 2.5em',
            }}
          >
            <FlexDiv
              style={{
                flexFlow: 'column',
              }}
            >
              <FeaturedTitle2>
                <Link
                  to="/stcaths"
                  style={{
                    textDecoration: 'none',
                    marginRight: '25px',
                    color: '#fff',
                  }}
                >
                  St. Catharines
                </Link>
              </FeaturedTitle2>
              <FeaturedSubtitle2>
                One of Ontario's hottest and upcoming Real Estate markets.
              </FeaturedSubtitle2>
            </FlexDiv>
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>
      {/* <FlexDiv
        style={{ display: 'flex', justifyContent: 'center', padding: 35 }}
      >
        <ButtonSubscribe className="viewAllCommBtn">
          View All Communities
        </ButtonSubscribe>
      </FlexDiv> */}
    </DivWrapper>
  )
}
